.banner {
  position: relative;
  .back-video {
    position: absolute; 
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    z-index: -100;
    background-size: cover;
    overflow: hidden;
  }
  .banner-container {
    max-width: 1375px;
    width: calc(100% - 40px);
    margin: auto;
    padding-bottom: 100px;
    min-height: 100vh;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 53px 0px 53px 0px;
      .logo{
        display: flex;
        align-items: center;
        img{
          margin-right: 20px;
          @media(max-width: 500px) {
            width: 40px;
            margin-right: 7px;
          }
        }
        span{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h3{
            font-size: 34px;
            margin: 0;
            color: #ffffff;
            text-align: left;
            @media(max-width: 500px) {
              font-size: 20px;
            }
          }
          p{
            font-size: 18px;
            margin: 0;
            color: #ffffff;
            text-align: left;
            @media(max-width: 500px) {
              font-size: 12px;
            }
          }

        }
      }
      .social-group {
        display: flex;
        align-items: center;
        column-gap: 70px;
        svg {
          color: white;
          font-size: 40px;
          cursor: pointer;
          transition: all 0.3s ease;
          @media(max-width: 500px) {
            font-size: 30px;
          }
          &:hover{
            opacity: 0.7;
          }
        }
        @media(max-width: 640px) {
          column-gap: 20px;
        }
      }
      img {
        @media(max-width: 500px) {
          width: 40%;
        }
      }
    }
    .banner-content {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      color: white;
      width: 60%;
      text-align: left;
      margin-top: min(200px, 10vw);
      .content-title {
        font-size: min(70px, 4vw);
        font-weight: 600;
        line-height: 1;


        @media(max-width: 640px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .content-container {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        .content-detail {
          font-size: min(22px, 1.2vw);
          font-weight: 600;
          line-height: 1.5;
          @media(max-width: 640px) {
            font-size: 18px;
            font-weight: 400;
          }
        }
        .text-button-group {
          display: flex;
          align-items: center;
          gap: 56.22px;
          .join-button {
            font-weight: 600;
            line-height: 33px;
            font-size: min(22px, 1.2vw);
            width: min(385.54px, 20vw);
            height: min(95.2px, 6vw);
            padding: 0px;
            text-align: center;
            border-radius: 16px;
            border: none;
            background: linear-gradient(to right, rgba(25, 17, 118, 1), rgba(95, 29, 148, 1), rgba(148, 29, 129, 1));
            color: white;
            @media(max-width: 640px) {
              font-size: 18px;
              font-weight: 400;
              height: 70px;
              width: 60%;
              margin: auto;
            }
            
          }
          .schedule-button {
            font-weight: 400;
            line-height: 33px;
            font-size: min(22px, 1.2vw);
            width: min(385.54px, 20vw);
            height: min(95.2px, 6vw);
            padding: 0px;
            text-align: center;
            border-radius: 16px;
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid #ffffff;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            @media(max-width: 640px) {
              font-size: 18px;
              font-weight: 400;
              height: 70px;
              width: 60%;
              margin: auto;
            }
          }
          @media(max-width: 428px) {
            gap: 20px;
          }
          @media(max-width: 344px) {
            flex-direction: column;
          }
        }
        @media(max-width: 1024px) {
          width: 100%;
        }
      }
      @media(max-width: 1172px) {
        width: 70%;
      }
      @media(max-width: 1024px) {
        width: 100%;
      }
      @media(max-width: 500px) {
        margin-top: 50px;
      }
    }
    @media(max-width: 1375px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
.content {
  position: relative;
  overflow: hidden;
  .content-video-box {
    position: absolute;
    height: 300vh;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    z-index: -100;
    .content-back-video {
      width: 150%;
      overflow-x: hidden;
      @media(max-width: 884px) {
        width: auto;
      }
    }
    @media(max-width: 1196px) {
      height: auto;
    }
  }
  .content-container {
    max-width: 1375px;
    margin: auto;
    .platform {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 50px;
      height: 100vh;
      .platform-title {
        .title {
          font-size: min(60px, 4vw);
          font-weight: 600;
          line-height: 1;
          @media(max-width: 640px) {
            font-size: 30px;
            font-weight: 500;
          }
        }
        .headline {
          font-size: min(18px, 1vw);
          font-weight: 600;
          line-height: 2;
          @media(max-width: 640px) {
            font-size: 12px;
          }
        }
      }
      .platform-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        .individual {
          max-width: 300px;
          width: 24%;
          @media(max-width: 640px) {
            width: 90%;
          }
          img {
            width: 50%;
            margin: auto;
          }
          .individual-body {
            text-align: left;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            
            .individual-title {
              font-size: min(32px, 1.5vw);
              font-weight: 700;
              line-height: 1.2;
              @media(max-width: 640px) {
                font-size: 24px;
              }
            }
            .individual-content {
              font-size: min(18px, 1vw);
              font-weight: 400;
              line-height: 1.5;
              @media(max-width: 640px) {
                font-size: 14px;
              }
            }
          }
        }
        @media(max-width: 640px) {
          justify-content: center;
        }
      }
      @media(max-width: 1375px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media(max-width: 640px) {
        height: 100%;
        padding-top: 20px;
        row-gap: 30px;
      }
      
    }
    .cases {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 100px;
      height: 100vh;
      @media(max-width: 640px) {
        height: auto;
        margin-bottom: 70px;
      }
      .cases-title {
        font-size: min(60px, 4vw);
        font-weight: 600;
        line-height: 1;
        @media(max-width: 640px) {
          font-size: 30px;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .img-box {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        .img-chat {
          width: 90%;
        }
        .tokenCircle {
          position: absolute;
          top: -7%;
          right: 2.5%;
          width: 168.25px;
          height: 168.25px;
          background-color: rgba(158, 82, 254, 1);
          border-radius: 50%;
          z-index: -1;
          @media(max-width: 1240px) {
            width: 120px;
            height: 120px;
          }
          @media(max-width: 640px) {
            width: 90px;
            height: 90px;
          }
          @media(max-width: 514px) {
            width: 60px;
            height: 60px;
          }
          @media(max-width: 278px) {
            width: 40px;
            height: 40px;
          }
        }
        .nftCircle {
          position: absolute;
          top: 40%;
          right: 26.5%;
          width: 93.21px;
          height: 93.21px;
          z-index: -1;
          background-color: rgba(53, 102, 225, 1);
          border-radius: 50%;
          @media(max-width: 1240px) {
            width: 70px;
            height: 70px;
          }
          @media(max-width: 640px) {
            width: 50px;
            height: 50px;
          }
          @media(max-width: 514px) {
            width: 40px;
            height: 40px;
          }
          @media(max-width: 278px) {
            width: 20px;
            height: 20px;
          }
        }
        .selectCircle {
          position: absolute;
          top: 56%;
          right: 0%;
          z-index: -1;
          width: 379.5px;
          height: 379.5px;
          background-color: rgba(53, 102, 225, 1);
          border-radius: 50%;
          @media(max-width: 1240px) {
            width: 250px;
            height: 250px;
          }
          @media(max-width: 714px) {
            width: 180px;
            height: 180px;
          }
          @media(max-width: 514px) {
            width: 120px;
            height: 120px;
          }
          @media(max-width: 278px) {
            width: 80px;
            height: 80px;
          }
        }
      }
      @media(max-width: 1375px) {
        padding-left: 20px;
        padding-right: 20px;
        row-gap: 50px;
      }
    }
    .powered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 50px;
      height: 100vh;
      .powered-header {
        color: white;
        display: flex;
        justify-content: center;
        .powered-title {
          display: flex;
          align-items: center;
          gap: 40px;
          .subtitle {
            font-size: min(60px, 4vw);
            font-weight: 600;
            line-height: 1;
            
            
            @media(max-width: 640px) {
              font-size: 20px;
              font-weight: 400;
            }
            
          }
          .subtitle-img {
            width: min(50px, 4vw);
            @media(max-width: 638px) {
              width: 24px;
            }
          }
          @media(max-width: 1024px) {
            gap: 20px;
          }
          @media(max-width: 640px) {
            gap: 7px;
          }
        }
      }
      .powered-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 70px;
        .powered-individual {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          max-width: 330px;
          width: 30%;
          height: 550px;
          @media(max-width: 640px) {
            width: 90%;
          }
          .powered-individual-content {
            display: flex;
            flex-direction: column;
            row-gap: 70px;
            @media(max-width: 382px) {
              row-gap: 40px;
            }
          }
          .more {
            width: inherit;
            color: white;
            text-align: left;
            span {
              padding-bottom: 3px;
              border-bottom: 1px solid white;
              text-align: left;
            }
            @media(max-width: 382px) {
              width: 90%;
            }
          }
          .powered-individual-icon {
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            .chain-circle {
              border-radius: 50%;
              border: 3px solid white;
              width: 103.17px;
              height: 103.17px;
              position: relative;
              .chain-white {
                width: 96.74px;
                height: 96.74px;
                border-radius: 50%;
                background-color: rgba(201, 198, 244, 1);
                z-index: -1;
                position: absolute;
                top: -45px;
                right: -55px;
              }
              .chain-white-small {
                width: 37.55px;
                height: 37.55px;
                border-radius: 50%;
                background-color: rgba(201, 198, 244, 1);
                z-index: -1;
                position: absolute;
                top: -40px;
                right: 115px;
              }
              .chain-blue {
                width: 61.32px;
                height: 61.32px;
                border-radius: 50%;
                background-color: #3566E1;
                z-index: -1;
                position: absolute;
                top: 65px;
                right: -5px;
              }
              .chain-pink {
                width: 49.64px;
                height: 49.64px;
                border-radius: 50%;
                background-color: rgba(158, 83, 254, 1);
                z-index: -1;
                position: absolute;
                top: 70px;
                right: 105px;
              }
            }
            .fast-icon {
              position: relative;
              margin-top: -100px ;
              .fast-icon-blue {
                .blue-fill {
                  width: 66.08px;
                  height: 66.08px;
                  border-radius: 50%;
                  background-color: #3566E1;
                  z-index: -1;
                  position: absolute;
                  top: -35px;
                  right: 10px;
                  z-index: -2;
                }
                .blue-circle {
                  width: 74.88px;
                  height: 74.88px;
                  border-radius: 50%;
                  border: 3px solid white;
                  z-index: -1;
                  position: absolute;
                  top: -50px;
                  right: 40px;
                  z-index: -1;
                }
              }
              .fast-icon-pink {
                .pink-fill {
                  width: 71.44px;
                  height: 71.44px;
                  border-radius: 50%;
                  background-color: rgba(158, 83, 254, 1);
                  z-index: -1;
                  position: absolute;
                  top: 40px;
                  right: 40px;
                  z-index: -2;
                }
                .pink-circle {
                  width: 59.87px;
                  height: 59.87px;
                  border-radius: 50%;
                  border: 3px solid white;
                  z-index: -1;
                  position: absolute;
                  top: 58px;
                  right: 81px;
                  z-index: -1;
                }
              }
              .fast-icon-white {
                .white-fill {
                  width: 71.74px;
                  height: 71.74px;
                  border-radius: 50%;
                  background-color: rgba(201, 198, 244, 1);
                  z-index: -1;
                  position: absolute;
                  top: 30px;
                  right: -65px;
                  z-index: -2;
                }
                .white-circle {
                  width: 78.93px;
                  height: 78.93px;
                  border-radius: 50%;
                  border: 3px solid white;
                  z-index: -1;
                  position: absolute;
                  top: 30px;
                  right: -35px;
                  z-index: -1;
                }
              }
            }
            .green-circle {
              position: relative;
              width: 104.6px;
              height: 104.6px;
              border-radius: 50%;
              border: 3px solid white;
              .circle-one {
                position: absolute;
                width: 45.95px;
                height: 45.95px;
                border-radius: 50%;
                border: 3px solid white;
                top: -50px;
                left: -10px;
                z-index: -1;
              }
              .circle-two {
                position: absolute;
                width: 45.95px;
                height: 45.95px;
                border-radius: 50%;
                border: 3px solid white;
                top: -37px;
                left: 96px;
                z-index: -1;
              }
              .circle-three {
                position: absolute;
                width: 45.95px;
                height: 45.95px;
                border-radius: 50%;
                border: 3px solid white;
                top: 79px;
                left: -54px;
                z-index: -1;
              }
              .circle-four {
                position: absolute;
                width: 45.95px;
                height: 45.95px;
                border-radius: 50%;
                border: 3px solid white;
                top: 97px;
                left: 90px;
                z-index: -1;
              }
              .fill-white-sm {
                position: absolute;
                width: 39.1px;
                height: 39.1px;
                border-radius: 50%;
                background-color: rgba(201, 198, 244, 1);
                top: -5px;
                left: -44px;
                z-index: -1;
              }
              .fill-white {
                position: absolute;
                width: 70.05px;
                height: 70.05px;
                border-radius: 50%;
                background-color: rgba(201, 198, 244, 1);
                top: 37px;
                left: 76px;
                z-index: -5;
              }
              .fill-blue {
                position: absolute;
                width: 68.99px;
                height: 68.99px;
                border-radius: 50%;
                background-color: rgba(53, 102, 225, 1);
                top: -40px;
                left: 5px;
                z-index: -5;
              }
              .fill-pink {
                position: absolute;
                width: 68.99px;
                height: 68.99px;
                border-radius: 50%;
                background-color: rgba(158, 82, 254, 1);
                top: 79px;
                left: -26px;
                z-index: -5;
              }
            }
          }
          .powered-individual-body {
            color: white;
            text-align: left;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            .body-title {
              font-size: min(32px, 2vw);
              font-weight: 600;
              line-height: 1.2;
              @media(max-width: 382px) {
                font-size: 22px;
                font-weight: 600;
              }
            }
            .body-content {
              font-size: min(18px, 1vw);
              font-weight: 400;
              line-height: 1.6;
              @media(max-width: 382px) {
                font-size: 15px;
                font-weight: 400;
              }
            }
            @media(max-width: 382px) {
              width: 90%;
              margin: auto;
            }
          }
          @media(max-width: 1375px) {
            row-gap: 50px;
          }
          @media(max-width: 766px) {
            row-gap: 30px;
            height: fit-content;
            justify-content: center;
          }
        }
        @media(max-width: 766px) {
          justify-content: center;
        }
      }
      @media(max-width: 1375px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media(max-width: 1196px) {
        height: 100%;
      }
      @media(max-width: 1196px) {
        padding-bottom: 20px;
      }
    }
  }
}
.footer {
  padding: 20px 0px 20px 0px;
  background: linear-gradient(to right, #05194d, #4e287e);
  .footer-container {
    max-width: 1375px;    
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    .footerLogo{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img{
        width: 96px;
        @media(max-width: 640px) {
          width: 60px;
        }
      }
      p{
        font-size: 12px;
        color: #ffffff;
      }
    }
    .footer-social {
      display: flex;
      column-gap: 25px;
      svg {
        color: white;
        font-size: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
  @media(max-width: 1375px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
